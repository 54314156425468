@font-face {
  font-family: 'TT-Norms';
  src: url(./assets/fonts/TTNorms-Medium.otf);
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: no-common-ligatures;
}

@font-face {
  font-family: 'TT-Norms-Regular';
  src: url(./assets/fonts/TTNorms-Regular.otf);
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: no-common-ligatures;
}

@font-face {
  font-family: 'TT-Norms-Black';
  src: url(./assets/fonts/TTNorms-ExtraBold.otf);
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: no-common-ligatures;
}

* {
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: no-common-ligatures;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.right-icon {
  display: block;
  margin-left: auto;
}

.login-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.login-text-container h1 {
  color: #151A35;
  font-size: 32px;
  line-height: 32px;
  font-family: TT-Norms-Black;
}

.login-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.login-text-container h1 {
  color: #151A35;
  font-size: 32px;
  line-height: 32px;
  font-family: TT-Norms-Black;
}

.login-button {
  background-color: #ddd;
  border-radius: 0px;
  border: none;
  color: #bbb;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  height: 50px;
}

.login-button:hover, .login-button:active, .login-button:focus {
  background-color: #25BF75;
  color: white;
}

.login-button img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.avatar-table {
  animation: fadeIn 500ms ease-out 0ms forwards;
}

.avatar-table  .ant-table-thead {
  display: none;
}

.avatar-table .login-button {
  color: white;
  width: 100%;
  background-color: #151A35;
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.ant-steps-item-subtitle {
  display: block;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot, 
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #00d17b;
}

.evidence-type {
  width: 150px;
  font-size: 20px;
  font-weight: bold;
  color: #151A35;
}

.empty-data {
  text-align: center;
}

.buttons-container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.language-select {
  width: 195px;
}

.select-filter-container {
  width: 82%;
}

button, .with-cursor-pointer {
  cursor: pointer;
}

button img {
  max-width: unset!important;
}

.modal-x-button {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  color: black;
  font-size: 20px;
}

table tr td{
  padding: 5px 10px!important;
}

table thead tr th {
  padding-left: 10px!important;
}

.new-button {
  cursor: pointer;
  width: 16px;
  margin-left: 10px;
}

.datepicker {
  color: lightgray!important;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
}

.copy-container {
  position: fixed;
  bottom: 5%;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  width: 250px;
  left: calc(50% - 125px);
  background-color: #151A35;
  color: white;
}

.dashboard-section {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
}

.documents-table .ant-pagination {
  display: none;
}

.custom-pagination-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
}

.custom-pagination-container .prev-next-container {
  background-color: #151A35;
  color: white;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  width: 100px;
}

.custom-pagination-container .ant-select-selector {
  width: 120px!important;
}

.full-width-select {
  width: 100%;
}

.img-height {
  height: 25px
}

.m-t-3 {
  margin-top: 15px;
}

.partner-total-amount {
  font-size: 60px;
  text-align: center;
}

.empty-data-label {
  text-align: center;
}

.view-more-button {
  width: 120px;
  display: block;
  margin: 10px auto;
}

@media (max-width: 480px) {
  .filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
  }

  .truncated {
    max-width: 200px;
  }

  .menu-burger {
    width: 50px;
  }

  .doc-detail-mob-table {
    padding: 0px 10px;
  }


  .lang-selector {
    display: none;
  }

  .header-nav-search {
    display: none;
  }

  .profile-menu-name {
    display: none;
  }

  .subs-large {
    display: none !important;
  }

  .app-content {
    padding: 0px;
  }

  /* .ant-tabs-tab {
    margin-right: 0px !important;
  } */

  .ant-tabs-tab-btn {
    font-size: 14px;
  }

  .subspan-stats-container {
    flex-wrap: wrap;
  }

  .subspan-stats {
    border: 1px solid #151A35 !important;
    text-align: center !important;
    max-height: unset !important;
  }

  .stat-p1 {
    font-size: 14px !important;
    font-family: 'TTNorms-Medium' !important;
  }

  .home-com-box {
    margin-top: 26px;
    border: 1px solid #C4C5C5;
  }

  .app-header,
  .app-header .app-header-wrapper {
    width: 100%;
  }


  .home-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 400px;
  }

  .home-container div {
    color: #fff;
    text-align: center;
  }

  .block-1 {
    grid-row: 1 / 5;
    grid-column: 1 / 3;
  }

  .block-2 {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
  }

  .block-3 {
    grid-row: 3 / 5;
    grid-column: 3 / 3;
  }

  .large-header-container input {
    margin-left: 5px!important;
  }


}

@media (min-width: 481px) {
  .subs-small {
    display: none
  }

  .mobile-val-nav {
    display: none;
  }
}

@media (min-width: 2200px) {
  .nav-right {
    margin-right: 120px;
  }
}

@media (min-width: 481px) and (max-width: 991px) {

  .nav-right {
    margin-right: 90px;
  }

  .menu-burger {
    display: none;
  }

  .mob-footer {
    display: none;
  }

  .app-header,
  .app-header .app-header-wrapper {
    width: 111%;
  }

  .home-com-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 0px 0px 2px 2px;
    border-top: 1px solid #25BF75;
    padding: 12px;
    opacity: 1;
    margin: 6px;
  }

  .ant-layout .app-layout {
    padding-left: 0px;
  }

  .ant-layout-header {
    padding-left: 0px !important;
  }

  .alt-logo-nav {
    padding: 0px 0px 0px 30px;

  }

  .header-nav-search {
    display: none;
  }

}

@media (min-width: 992px) {

  .menu-burger {
    display: none;
  }

  .mob-footer {
    display: none;
  }

  .app-header,
  .app-header .app-header-wrapper {
    width: 111%;
  }

  .home-com-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 0px 0px 2px 2px;
    border-top: 1px solid #25BF75;
    padding: 12px;
    opacity: 1;
    margin: 6px;
  }


  .ant-layout .app-layout {
    padding-left: 300px !important;
  }

  .ant-layout-header {
    padding-left: 310px !important;
    background-color: white !important;
  }

  .alt-logo-nav {
    display: none;
  }

  .sidenav-logo-cnt {
    text-align: center;
    padding: 20px 0px 0px 0px;
    background-color: white;
    height: 70px;
  }

  .header-nav-search {
    border: 1px solid #151A35;
    border-radius: 16px;
    opacity: 1;
    height: 32px;
    margin-top: 20px;
    width: 400px;
    padding-left: 20px;
  }



}



.app-content {
  margin-top: 70px;
}

.footer {
  background-color: white;
  margin: 0px;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}


.separator-vl {
  border-left: 1px solid #151A35;
  height: 38px;
  display: inline-table;
  margin: -12px 4px;
}

.lang-selector {
  margin-left: 14px;
}

.profile-menu-name {
  margin-right: 4px;
}

.loading .ant-spin {
  color: #3e79f7;
}

.ant-layout-sider-children .ant-menu {
  background-color: #151A35;
  font-family: 'TTNorms-Light', serif;
  font-size: 15px;
  margin-top: 20px;
}

.ant-layout-slider {
  flex: 0 0 310px !important;
  max-width: 310px !important;
  min-width: 310px !important;
  width: 300px !important;
}

.ant-layout-sider-children {
  box-shadow: none;
  background-color: #151A35;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.trusted-cert-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00BF71;
}

.trusted-cert-form-group {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  width: 694px;
  border-bottom: 1px solid #ECECEC;
}

.trusted-cert-form-group p {
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.trusted-cert-form-group .ant-form-item {
  margin-bottom: 0px;
}

.add-trusted-cert-button {
  width: Fixed (196px)px;
  height: Fixed (40px)px;
  padding: 10px 15px 10px 15px;
  gap: 0px;
  border-radius: 30px;
  border: 1px solid #00BF71;
  background: #00BF711A;
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #00BF71;
  margin-top: 20px;
}

.add-trusted-cert-button:disabled {
  border: 1px solid grey;
  background-color: lightgray;
  color: black;
  cursor: not-allowed;
}

.ant-upload-drag-icon {
  display: flex;
  gap: 20px;
  align-items: center;
}

.ant-upload-drag-icon p {
  margin-bottom: 0!important;
}

.side-nav {
  z-index: 1000;
  top: 0px;
  height: 100%;
}

:root {
  --unnamed-font-family-tt-norms: TT Norms;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-16: 16px;
}


/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-tt-norms);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-737373);
}


.mana-user-form-item {
  margin-bottom: 0px;
  font-size: 12px;
  color: #151A35;
}


.mana-user-form-item span {
  font-size: 12px;
  color: #151A35;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.ant-modal-close-x {
  display: none !important;
}

.sidenav-logo-cnt img {
  width: 164px;
}


.achotio {
  background-color: #f3f3f3 !important;
}

.achotio>span {
  font-family: 'TTNorms-Medium' !important;
  color: #151A35 !important;
}

.ant-menu-item-selected {
  /* background-color: #f3f3f3 !important; */
  background-color: none !important;

}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: none !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
  font-family: unset !important;
  color: none !important;
}

.ant-menu-item-selected.ant-menu-item-group,
.ant-menu-item-selected.ant-menu-item-group a,
.ant-menu-item-selected.ant-menu-item-group a:hover,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-item-selected.ant-menu-item-group .ant-menu-item-selected.achotio p
{
  font-family: TT-Norms !important;
  color: #151A35 !important;
  font-weight: bold;
}

.ant-menu-item {
  padding-left: 64px !important;
}



@font-face {
  font-family: 'TTNorms';
  font-style: normal;
  font-weight: normal;
  src: local('TTNorms'), url('./TTNorms-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'TTNorms-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('TTNorms-Medium'), url('./TTNorms-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'TTNorms-Light';
  font-style: normal;
  font-weight: normal;
  src: local('TTNorms-Light'), url('./TTNorms-Light.otf') format('opentype');
}

body {
  font-family: 'TTNorms', serif;
  /* font-size: 48px; */
}


.ant-tabs-ink-bar {
  background: #25BF75;
}

.subs-left-panel {
  border-top: 1px solid rgb(230, 235, 241);
  border-right: 1px solid rgb(230, 235, 241);
  margin-top: 34px;
  background-color: #F6F6F6;
  min-height: 600px;

}

.m-h-600 {
  min-height: 600px;
}

.subs-left-panel-embedded {
  background-color: white;
  min-height: unset;
}

.subs-list-embedded.active {
  background-color: #F6F6F6 !important;
}

.subs-list {
  color: #737373 !important;
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-left: -24px;
  padding-left: 30px;
}

.subs-row {
  border-bottom: 1px solid rgb(230, 235, 241);
  margin-bottom: 8px;
}

.rtn-button {
  margin-top: 18px;
}

.subs-list p {
  margin: 0px;
}

.subs-list.fst {
  margin-top: 20px;
}

.subs-list.active {
  background-color: white;
}

.subs-label {
  color: #151A35;
  font-size: 12px;
  font-family: 'TTNorms-Medium';
  margin-left: 32px !important;
}

.hdn-search {
  visibility: hidden;
}

.subs-label .dot-success {
  margin-right: 4px;
}

.customer-label {
  color: #151A35;
  font-size: 12px;
  font-family: 'TTNorms';
  margin-right: 46px !important;
}

.large-header-container {
  display: flex;
  justify-content: flex-start;
  margin: 4px 0px 12px 0px;
}


.large-header-container h2 {
  color: #151A35;
  font-size: 18px;
  font-weight: 700;
}

.large-header-container input {
  width: 380px;
  padding: 0px 14px;
  border: 2px solid #e4e4e475;
  border-radius: 2px;
  margin-left: 70px;

}

.large-header-container input::placeholder {
  color: #C4C5C5;
}

.rtrn-img {
  cursor: pointer;
  margin-left: 8px;
}

.ant-table-thead>tr>.ant-table-cell {
  font-size: 12px !important;
  font-family: 'TTNorms';
}

th.ant-table-cell {
  font-family: 'TTNorms-Light';
  color: #C4C5C5 !important;
}

.dot-success {
  height: 10px;
  width: 10px;
  background-color: #25BF75;
  border-radius: 50%;
  display: inline-block;
}

.filter-label {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium' !important;
  margin-left: 8px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.go-back {
  color: #151A35 !important;
  font-family: 'TTNorms' !important;
  margin-left: 8px;
  font-size: 10px;
  cursor: pointer;
  padding-top: 4px;
  margin-left: 24px;
  white-space: nowrap;
}

.filter-label>img {
  margin-right: 6px;
}

.dot-danger {
  height: 10px;
  width: 10px;
  background-color: #E66767;
  border-radius: 50%;
  display: inline-block;
}

.dot-warning {
  height: 10px;
  width: 10px;
  background-color: #D6B35F;
  border-radius: 50%;
  display: inline-block;
}

.success {
  color: #25BF75;
}


.danger {
  color: #E66767;
}


.warning {
  color: #D6B35F;
}

.ant-menu {
  height: unset !important;
}

 .ant-layout-sider-children .ant-menu-item {
  min-height: 64px !important;
  padding-top: 10px !important;
}

.ant-menu-item img {
  margin-right: 10px;
}

.ant-menu-item-group-list p {
  color: white;
  margin-left: 32.5px;
}

.ant-menu-inline > .ant-menu-item {
  height: unset;
}

.form-group-container {
  display: flex;
  margin-bottom: 30px;
}

.nu-card {
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 0px 0px 2px 2px;
  opacity: 1;
  color: #151A35;
}

.nu-card-header hr {
  border: 1px solid #E4E4E4;
  font-size: 12px;
}

.nu-card-header p {
  margin: 0px;
  padding: 6px 14px;
  color: #151A35;
  font-size: 12px;
  font-weight: 600;
}

.nu-card-body {
  color: #737373 !important;
  font-size: 12px;
  padding: 2px 0px 14px 12px;
}

.nu-card-body>.ant-checkbox-wrapper {
  font-size: 12px;
  color: #151A35;
  width: 100%;
  margin-left: 0px !important;
}

.filters-check>.ant-checkbox-wrapper {
  font-size: 12px;
  color: #151A35;
  width: 100%;
  margin-left: 0px !important;
}

.filters-check {
  display: table-caption;
}

.smaller-btn {
  width: 50%;
  display: block;
  margin: 10px auto;
}

.filters-check label.ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.filter-modal-input-label {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium' !important;
  font-size: 13px;
}

.filter-checkbox>span {
  font-size: 11px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.nu-col {
  margin: 0px 8px;
}

.new-user-header {
  display: flex;
  flex-wrap: wrap;
}

.new-user-header h2 {
  color: #151A35;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 700;
}

.new-user-header hr {
  border: 1px solid #E4E4E4;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.line-break {
  width: 100%;
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(37 191 117) !important;
  border-color: rgb(255 255 255) !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgb(37 191 117) !important;
}

.nu-input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 192px;
  height: 30px;
  margin-right: 26px;
  border: 2px solid #e4e4e475;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 12px;
}

.nu-input:focus {
  outline: none;
}


.nu-input-label {
  font-family: 'TTNorms-Light';
  margin-bottom: 4px;
  margin-left: 6px;
  font-size: 12px;
  color: #151A35;
}

.new-user-form h2 {
  font-size: 18px;
  color: #151A35;
}

.app-header.ant-layout-header {
  height: 70px;
}

.sidenav-legal-u {
  color: #C4C5C5;
  font-family: 'TTNorms-Light';
  font-size: 12px;
  text-align: center;
}

.sidenav-legal-b {
  bottom: 32px;
  position: absolute;
  color: #C4C5C5;
  font-family: 'TTNorms-Light';
  font-size: 12px;
  left: 66px;
}

.search-input::placeholder {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium' !important;
  font-size: 12px;
}

.nu-user {
  font-size: 12px;
  margin-left: 30px;
  cursor: pointer;
  font-family: 'TTNorms-Medium';
}

.nu-user img {
  margin-right: 6px;
}

.ant-table-tbody {
  color: #737373 !important;
  font-family: 'TTNorms-Light' !important;
  font-size: 13px !important;
}

.delete-icon {
  cursor: pointer;
  width: 16px;
}

.save-icon {
  cursor: pointer;
  width: 18px;
}

.download-container {
  background-color: #F3F3F3;
  padding: 6px 2px;
  width: 106px;
  cursor: pointer;
}

.download-container>span {
  font-family: 'TTNorms-Medium';
}



.avatar-lst-container {
  /* background-color: #F3F3F3; */
  padding: 6px 2px;
  cursor: pointer;
}

.avatar-lst-container>span {
  font-family: 'TTNorms-Medium';
}



.download-icon {
  margin: 0px 7px;
}


.create-user-btn-container {
  background-color: #F3F3F3;
  padding: 6px 2px;
  width: 150px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.create-user-btn-container>span {
  font-family: 'TTNorms-Medium';
}

.create-user-btn-icon {
  margin: 0px 7px;
}

.subspan-header {
  display: flex;
  gap: 15px;
}

.subspan-header p {
  color: #151A35;
  font-size: 12px;
}

.subspan-stats-container {
  display: flex;
  margin-top: 18px;
}

.subspan-stats {
  text-align: end;
  border: 1px solid #E4E4E4;
  border-radius: 2px;
  padding: 0px 10px;
  margin-left: 20px;
  min-width: 52px;
  max-height: 50px;
}

.stat-p1 {
  margin: 0px;
  font-size: 10px;
  color: #151A35;
  font-family: 'TTNorms';
}


.stat-p2 {
  font-size: 22px;
  color: #151A35;
  margin: 0px;
  margin-top: -6px;
  font-family: 'TTNorms-Medium';

}

.bio-separator {
  margin-top: 20px;
}

.bio-separator hr {
  border: 1px solid #e4e4e452;
  opacity: 1;
}

.bio-separator img {
  margin-right: 6px;
}

.bio-separator p {
  margin-left: 20px;
  color: #151A35;
  font-size: 12px;
}

.centralized-separator {
  margin-top: 20px;
}

.centralized-separator hr {
  border: 1px solid #e4e4e452;
  opacity: 1;
}

.centralized-separator img {
  margin-right: 6px;
}

.centralized-separator p {
  margin-left: 20px;
  color: #151A35;
  font-size: 12px;
  cursor: pointer;
}

.centralized-separator>div {
  display: flex;
  justify-content: space-between;
}

.remote-date-range, .date-range {
  background-color: #3e79f7;
  color: #E4E4E4;
  padding: 4px;
  border-radius: 4px;
  min-width: 150px;
}

.remote-date-range img, .date-range img {
  margin-right: 6px;
}

.remote-date-range, .date-range img {
  font-size: 12px;
}

.subspan-table {
  margin-left: 20px;
}

.subspan-table th {
  font-weight: 100 !important;
  font-size: 12px;
  font-family: 'TTNorms-Light';
}

.subspan-table th {
  padding: 10px 16px !important;
}

.subspan-table .ant-table-thead th {
  border-bottom: 0px;
}

.device-header-container {
  display: flex;
  justify-content: flex-start;
  margin: 4px 0px 12px 30px;
}

.device-header-container input {
  width: 350px;
  padding: 0px 14px;
  border: 2px solid #e4e4e475;
  border-radius: 2px;
  margin-left: -20px;
}

.subscriptions-header-container {
  display: flex;
  justify-content: flex-start;
  margin: 4px 0px 12px 30px;
}

.header-embedded {
  margin: 4px 0px -30px 30px;
}

.subscriptions-header-container h2 {
  color: #151A35;
  font-size: 18px;
  font-weight: 700;
}

.subscriptions-header-container input {
  width: 380px;
  padding: 0px 14px;
  border: 2px solid #e4e4e475;
  border-radius: 2px;
  margin-left: 70px;
}

.subscriptions-header-container input::placeholder {
  color: #C4C5C5;
  font-size: 12px;
  font-family: 'TTNorms-Light';
}

.subscriptions-tabs .ant-tabs-tab {
  color: #151A35;
  font-size: 12px !important;
  padding-bottom: 2px;
}

.subscriptions-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #151A35;
}

.subscriptions-tabs .ant-tabs-nav-list {
  padding-bottom: 3px;
}

.centralized-modal .ant-modal-content {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 2px 2px;
  opacity: 1;
  padding: 28px;
}

.ant-modal-mask {
  background-color: rgb(0 0 0 / 15%);
  backdrop-filter: blur(3px);
}

.ant-modal-header {
  border-bottom: none;
}

.centralized-form-group {
  margin-bottom: 18px;
  margin-bottom: 32px;
}

.valadmin-btn {
  border: 1px solid #737373;
  padding: 2px 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 2px;
  color: #151A35;
  font-family: 'TTNorms-Medium';
  font-size: 14px;
  cursor: pointer;
  margin-right: 8px;
}

.modal-action-buttons {
  margin-top: 40px;
  display: flex;
}

.ant-modal-title {
  color: #151A35;
  font-family: 'TTNorms-Medium';
  font-size: 18px;
}

.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  border-right: none;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  color: #FFFFFF;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
  font-family: 'TTNorms-Medium';
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  font-family: 'TTNorms-Medium';
  color: #FFFFFF;

}

.create-user-icon {
  margin-left: 4px;
}

.subspan-status-active {
  color: #25BF75 !important;
}

.subspan-status-inactive {
  color: #E66767 !important;
}

.spinner-table {
  text-align: center;
  margin-top: 50px;
  transition: 0.5s;
}

.spinner-table-animation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.ant-spin-dot-item {
  background-color: rgb(37 191 117);
}


thead[class*="ant-table-thead"] th {
  color: #151A35 !important;
  font-size: 14px;
  font-family: 'TTNorms-Medium', serif;
}

.ant-table-tbody>tr>td {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium';
  padding: 12px 0px;
}

.app-header {
  box-shadow: none !important;
}

.profile-menu-item {
  height: 35px !important;
  padding-top: 0px !important;
  padding-left: 16px !important;
}

.profile-menu-item div {
  color: black;
}

.subs-title {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium' !important;
  font-size: 24px;
}

.bio-small-container {
  display: flex;
  justify-content: space-between;
}

.b-s-t1 {
  margin-bottom: 0px;
  color: #151A35;
  font-size: 16px;
}

.b-s-t2 {
  margin-bottom: 0px;
  color: #737373;
}

.b-s-t3 {
  margin-bottom: 0px;
  font-size: 16px;
  color: #737373;
}

.b-s-t4 {
  margin-bottom: 0px;
  text-align: end;
  font-size: 22px;
  margin-top: -10px;
  font-family: 'TTNorms';
}

.b-s-t4 {
  margin-bottom: 0px;
  text-align: end;
  font-size: 15px;
  margin-top: -4px;
  font-family: 'TTNorms';
}




.small-table-bio {
  margin-top: 34px;
}

.subs-label-small {
  color: #151A35;
  font-size: 16px;
  font-family: 'TTNorms';
  margin-left: 14px !important;
  margin-bottom: 0px;
}

.dot-success-small {
  height: 7px;
  width: 7px;
  background-color: #25BF75;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
}

hr.solid {
  border-top: 0px solid #bbb;
}

.border-g {
  border: 1px solid #07BF71;
}

.doc-small-container {
  padding: 0px 20px;
}


.mobile-val-nav {
  margin-top: 70px;
}

.mobile-val-nav>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #151A35;
  text-align: center;
}

.mobile-val-nav>ul>li {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}


.mobile-val-nav>ul>li a {
  display: block;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  font-family: 'TTNorms-Light';
  text-align: left;
}

.mobile-val-nav>ul>li.active {
  background-color: white;
}

.mobile-val-nav>ul>li.active>a {
  color: #151A35;
}

.mobile-val-nav>ul>li a.active {
  background-color: white;
  color: #151A35;
  font-family: 'TTNorms-Light';
}


.last-nav-item {
  padding-bottom: 20px;
}


.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border: unset;
}

.ant-pagination-item-active a,
.code-box-carousel-demo .ant-carousel .slick-slide h3 {
  color: rgb(120 120 120);
}

.ant-pagination-item-active {
  background: unset;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: unset;
}

.ant-pagination {
  font-size: 11px;
}

.ant-table-pagination>* {
  margin: 0px;
  flex: 0 0 auto;
}

.header-nav-search:focus {
  outline: none;
}

.date-range {
  cursor: pointer;
}

.documents-link {
  cursor: pointer;
  margin-left: 20px;
}

.documents-link>img {
  width: 7px;
  margin-right: 4px;
}


.lang-selector>span {
  cursor: pointer;
}

.active-lang {
  font-weight: 900;
}

.profile-menu-item.ant-menu-item-selected {
  background-color: unset !important;
}

.header-nav-search {
  background: url('./search_icon.svg') no-repeat scroll 14px 7px;
  padding-left: 34px;
}

.settings-col {
  text-align: center;
}

.settings-col>img {
  width: 130px;
}

.customer-detail-header {
  margin-bottom: 40px;
}

.customer-detail-subt {
  font-size: 12px;
  margin-bottom: 0px;
  /* margin-left: 20px; */
}

.cust-detail-table {
  margin: 0px 14px;
}

.login-logo {
  width: 230px;
  margin-bottom: 26px;
}

.login-card {
  border-radius: 0px;
}

.login-btn {
  background: #151A35;
  border: none;
  border-radius: 0px;
}

.role-container,
.delete-user-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.delete-user-container {
  cursor: pointer;
  color: red;
}

.role-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.circle-partner {
  background-color: orangered;
}

.circle-owner {
  background-color: blue;
}

.circle-customer {
  background-color: blueviolet;
}

.options-table-container {
  display: flex;
  gap: 15px;
}

.options-table-container img {
  width: 20px;
}


.home-com-box-settings {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 0px 0px 2px 2px;
  border-top: 1px solid #25BF75;
  padding: 22px;
  opacity: 1;
  margin: 6px;
}

.settings-avatar {
  margin: 40px 0px;
}

.scroll-avatar {
  overflow-y: scroll;
  height: 250px;
}

.link-table-cell {
  color: #151A35 !important;
  font-family: 'TTNorms-Medium';
  cursor: pointer;
  margin: 0px;
}

.subt-partner {
  font-size: 16px !important;
  margin-top: 14px !important;
  cursor: pointer;
}

.link-custo-part {
  display: grid;
}

.subspan-header .link-span {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #151A35;
  font-size: 12px;
  cursor: pointer;
}

.subspan-header .link-span>img {
  width: 16px;
}

.documents-link {
  color: #151A35;
  font-size: 12px;
  margin-left: 20px;
}

.documents-link>img {
  width: 8px;
  margin-right: 4px;
}

.doc-detail-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.doc-detail-actions svg {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.doc-detail-header {
  flex-direction: column;
  flex-wrap: wrap;
  text-align: end;
  margin-bottom: 24px;
}

.doc-detail-header h2 {
  cursor: pointer;
}


.nu-submit {
  display: flex;
  margin-top: 24px;
  margin-left: 15px;
}

.cust-detail-mob {
  margin-top: 24px;
}

.lang-select-input {
  margin-top: 16px;
  height: fit-content;
}

.form-group-container .ant-select, .ant-select {
  width: 100%;
}

.error-label { 
  color: red;
}

td {
  user-select: none;
}

iframe {
  pointer-events: none;
}

.download-container {
  color: unset;
}

.download-container span {
  color: unset;
}

.filter-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.check-all-container {
  display: flex;
  gap: 5px;
  padding: 8px;
}

.check-all-container button, .create-user-btn-container {
  background-color: #151A35;
  color: white;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 4px;
}

.create-user-btn-container {
  padding: 6px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-all-container button:disabled, .create-user-btn-container:disabled {
  background-color: #737373;
  color: white;
  cursor: unset;
}

.regular-text {
  color: black;
}

.img-fluid {
  max-width: unset;
}

.log-step {
  color: #00d17b;
}

.log-type {
  margin-top: 1%;
}

.subscription-customization-column {
  background-color: white;
  box-shadow: 0px 0px 16px 0px #0000000F;
  border-radius: 2px;
  padding: 20px;
  height: 50vh;
  overflow-y: auto;
}

.customizable-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.customizable-item .customizable-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customizable-item .customizable-item-header .customization-selector, 
.customizable-item .customizable-item-header input,  
.customizable-item .customizable-item-header .ant-input-number,
.customizable-item .customizable-item-header .ant-select {
  width: 170px!important;
}

.customizable-item .customizable-item-header .customize-button {
  width: 123px;
  height: 40px;
  padding: 10px 15px 10px 15px;
  border-radius: 30px;
  background: #00BF711A;
  border: 1px solid #00BF71;
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #00BF71;
}

.customizable-item .customizable-item-header span {
  font-weight: bold;
}

.customizable-item .customizable-item-header .ant-switch-checked {
  background-color: #25BF75;
}

.customizable-item p {
  margin-bottom: 0;
}

hr {
  margin-bottom: 0;
  border: 1px solid #ECECEC;
}

.subscription-info-row {
  display: flex;
  gap: 5px;
}

.subscription-info-row p, .subscription-info-row > span {
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  padding: 5px;
  height: 30px;
}

.ant-menu-item-group-title {
  display: none;
}

.create-form {
  background-color: white;
  padding: 40px;
}

.create-form-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.create-form-group {
  display: flex;
  flex-direction: row;
}

.create-form-group .ant-form-item {
  margin-bottom: 0;
}

.create-form-group p {
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  width: 200px;
}

.create-form-group input, .create-form-group textarea {
  margin-right: 0;
  width: calc(100% - 200px);
}

.create-form-group textarea {
  height: 200px!important;
}

.create-form-group .ant-select {
  width: 75px;
}

.create-form-group .ant-switch {
  width: 50px;
}

.permission-subcategory-container {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission-subcategory-permissions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.permission-subtype-select, .create-form-group .permission-subtype-select {
  width: 100%;
}

.permission-subgroup-container {
  display: flex;
  align-items: center;
  gap: 20px
}

.permission-subgroup-container .subgroup-title {
  font-weight: bold;
  font-family: TT-Norms;
  font-size: 18px;
  min-width: 150px;
}

.permission-subgroup-container .trusted-cert-form-group {
  border-bottom: 0;
  width: unset;
}

.permission-subgroup-container .trusted-cert-form-group .ant-select {
  width: 150px;
}

.search-container input {
  height: 100%;
}

.large-header-container {
  gap: 20px;
}

.button-blue {
  background-color: #151A35;
  color: white;
  border: none;
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.button-blue:disabled {
  background-color: #737373;
  color: black;
  cursor: unset;
}

.change-subs-password-modal .create-form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.change-subs-password-modal .create-form-group input {
  border: 1px solid black;
  border-radius: 4px;
  width: 100%;
}

.change-subs-password-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.change-subs-password-modal-footer button {
  height: 40px;
  width: 134px;
}

.change-subs-password-modal .validation-error {
  color: red;
  margin-left: auto;
  display: block;
  margin-bottom: 0;
}

@media screen and (max-width: 1000px) {
  .subscription-info-row {
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .permission-subgroup-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .create-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .create-form-group p {
    width: auto;
  }

  .create-form-group input, .create-form-group textarea {
    margin-right: 0;
    width: auto;
  }

  .create-container {
    display: unset;
  }

  .create-form-column:nth-child(2) {
    margin-top: 20px;
  }

  .permissions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .permissions-container .nu-col {
    max-width: unset;
  }

  .subs-small .subscriptions-header-container, .subs-small .large-header-container, .large-header-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .search-container {
    display: flex;
  }

  .search-container input {
    height: unset;
  }

  .subs-small .subscriptions-header-container input {
    margin-left: 0;
  }

  .ant-modal-body .nu-input {
    width: 85%;
  }
}